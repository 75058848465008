<template>
  <div class="py-[20px] px-[30px] flex header items-center justify-between border-b-[1.5px] borderBox">
    <div class="flex items-center justify-between w-full">
      
      <div @click="$emit('toggleSidebar')" class="z-[10] rounded-full cursor-pointer">
        <svg v-if="!isDark" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M5.40002 2.09998H18.6C19.7 2.09998 20.6 2.99998 20.6 4.09998V6.29998C20.6 7.09998 20.1 8.09998 19.6 8.59998L15.3 12.4C14.7 12.9 14.3 13.9 14.3 14.7V19C14.3 19.6 13.9 20.4 13.4 20.7L12 21.6C10.7 22.4 8.90002 21.5 8.90002 19.9V14.6C8.90002 13.9 8.50002 13 8.10002 12.5L4.30002 8.49998C3.80002 7.99998 3.40002 7.09998 3.40002 6.49998V4.19998C3.40002 2.99998 4.30002 2.09998 5.40002 2.09998Z" :stroke="$isDark ? '#fff' : '#888A8F'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.93 2.09998L6 9.99998" :stroke="$isDark ? '#fff' : '#888A8F'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M5.40002 2.09998H18.6C19.7 2.09998 20.6 2.99998 20.6 4.09998V6.29998C20.6 7.09998 20.1 8.09998 19.6 8.59998L15.3 12.4C14.7 12.9 14.3 13.9 14.3 14.7V19C14.3 19.6 13.9 20.4 13.4 20.7L12 21.6C10.7 22.4 8.90002 21.5 8.90002 19.9V14.6C8.90002 13.9 8.50002 13 8.10002 12.5L4.30002 8.49998C3.80002 7.99998 3.40002 7.09998 3.40002 6.49998V4.19998C3.40002 2.99998 4.30002 2.09998 5.40002 2.09998Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.93 2.09998L6 9.99998" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <h1 class="mainTitle">{{ getHeaderTitle() }}</h1>

      <div class="fled justify-end overflow-hidden rounded-[12px]">
        <div v-if="!isDark" @click="$emit('changeTheme', true)" class="cursor-pointer w-full h-full flex items-center justify-center">
          <SunIcon width="30" height="30" />
        </div>
        <div v-else @click="$emit('changeTheme', false)" class="cursor-pointer w-full h-full flex items-center justify-center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M2.03009 12.42C2.39009 17.57 6.76009 21.76 11.9901 21.99C15.6801 22.15 18.9801 20.43 20.9601 17.72C21.7801 16.61 21.3401 15.87 19.9701 16.12C19.3001 16.24 18.6101 16.29 17.8901 16.26C13.0001 16.06 9.00009 11.97 8.98009 7.13996C8.97009 5.83996 9.24009 4.60996 9.73009 3.48996C10.2701 2.24996 9.62009 1.65996 8.37009 2.18996C4.41009 3.85996 1.70009 7.84996 2.03009 12.42Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import ProfileDropdown from "@/components/base/ProfileDropdown.vue";
import Modal from "../../components/base/Modal.vue";
import Notification from "../../components/base/Notification.vue";
import SunIcon from "@/assets/iconsVue/SunIcon.vue";
import { useI18n } from 'vue-i18n'

export default {
  props: ['isDark'],
  watch: {
      isDark: function (newValue, oldValue) {},
  },
  components: {
    ProfileDropdown,
    Modal,
    Notification,
    SunIcon,
  },
  setup() {
    const instance = getCurrentInstance();
    const globalVariables = instance.appContext.config.globalProperties;
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n()

    function getHeaderTitle() {
      switch (route.name) {
        case "Admins":
          return globalVariables.$t('staff_admins');
        case "Profile":
          return globalVariables.$t('profile_settings');
        case "Factories":
          return globalVariables.$t('mattress');
        case "Pillow":
          return globalVariables.$t('pillow');
        case "Sponge":
          return globalVariables.$t('sponge');
        case "CurlyCutting":
          return globalVariables.$t('curly_cutting');
        case "Sailing":
          return globalVariables.$t('sales');
        case "RawMaterial":
          return globalVariables.$t('raw');
        case "Equipment":
          return globalVariables.$t('equipment');
        case "Machines":
          return globalVariables.$t('tech');
        case "Orders":
          return globalVariables.$t('orders');
        case "Customers":
          return globalVariables.$t('clients');
        case "OrdersModal":
          return globalVariables.$t('Форма заказ');
        case "SaleModal":
          return globalVariables.$t('sales');
        case "CustomersModal":
          return globalVariables.$t('Форма клиента');
        case "SalledProduct":
          return globalVariables.$t('Товар');
        default:
          return "LFR.uz";
      }
    }



    return {
      getHeaderTitle,
    };
  },
};
</script>
