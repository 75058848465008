import { createI18n } from 'vue-i18n'

import uz from './locale/uz'
import ru from './locale/ru'

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "ru",
    fallbackLocale: "ru",
    messages: {
        uz,
        ru,
    }
})

export default i18n