<template>
  <div class="footer" :class="isDark ? 'bg-[#1f3854] text-color-white' : ''">
    <ul class="footer__pages">
        <li class="footer__sales">
            <router-link :to="{ name: 'Sailing' }">
                <SailingHistory stroke="stroke-current" width="24" />
                <span>{{$t('sales')}}</span>
            </router-link>
        </li>
        <li class="footer__orders cursor-pointer">
            <!-- <router-link :to="{ name: 'Orders' }"> -->
                <CalendarEditIcon stroke="stroke-current" width="24" />
                <span> {{$t('orders')}} </span>
            <!-- </router-link> -->
        </li>
        <li class="footer__plus">
            <router-link :to="{ name: 'SaleModal' }">
                <PlusIcon stroke="stroke-current" width="40" />
            </router-link>
            </li>
        <li class="footer__client">
            <router-link :to="{ name: 'Customers' }">
                <PeopleIcon stroke="stroke-current" width="24" />
                <span>{{$t('clients')}}</span>
            </router-link>
        </li>
        <li class="footer__profile">
            <router-link :to="{ name: 'Profile' }">
                <AdminsIcon stroke="stroke-current" width="24" />
                <span>{{$t('profile')}}</span>
            </router-link>
        </li>
    </ul>
  </div>
</template>

<script>
import AdminsIcon from "@/assets/iconsVue/AdminsIcon.vue";
import SailingHistory from "@/assets/iconsVue/SailingHistory.vue";
import MatrasIcon from "@/assets/iconsVue/MatrasIcon.vue";
import PeopleIcon from "@/assets/iconsVue/PeopleIcon.vue";
import CalendarEditIcon from "@/assets/iconsVue/CalendarEditIcon.vue";
import PlusIcon from "@/assets/iconsVue/PlusIcon.vue";
export default {
    components: {
        AdminsIcon,
        SailingHistory,
        MatrasIcon,
        PeopleIcon,
        CalendarEditIcon,
        PlusIcon  
    },
    props: ['isDark'],

}
</script>

<style lang="scss">
.text-color-white {
    span{
        color: #fff !important;
        opacity: 1 !important;
    }

    svg{
        path{
            stroke: #fff !important;
        }
    }
}
.fill-white{
    fill: #fff;
}
</style>