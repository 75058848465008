import {createRouter, createWebHistory} from 'vue-router'
import {authStore} from '../store/auth';
import {ability} from '../plugins/ability';

const routes = [
    {
        path: '/',
        component: () => import('../views/Login/Login.vue'),
        name: 'Login',
        meta: {authRequired: false, layout: 'empty'},
    },
    {
        path: '/',
        component: () => import('../layouts/MainLayout.vue'),
        meta: {authRequired: true},
        children: [
            {
                path: 'sale/modal', name: 'SaleModal', component: () => import('../views/Selling/components/Form.vue'),
                meta: {authRequired: true}
            },
            {
                path: 'sale/product', name: 'SalledProduct', component: () => import('../views/Selling/components/Product.vue'),
                meta: {authRequired: true}
            },
            {
                path: 'profile', name: 'Profile', component: () => import('../views/Profile/Profile.vue'),
                meta: {authRequired: true}
            },
            {
                path: 'sale', name: 'Sailing', component: () => import('../views/Selling/Selling.vue'),
                meta: {authRequired: true}
            },   
            {
                path: 'customers', name: 'Customers', component: () => import('../views/Customers/Index.vue'),
                meta: {authRequired: true}
            },
            {
                path: 'customers/modal', name: 'CustomersModal', component: () => import('../views/Customers/components/Form.vue'),
                meta: {authRequired: true}
            },
            {
                path: 'customers/show/:id', name: 'CustomersShow', component: () => import('../views/Customers/Show.vue'),
                meta: {authRequired: true}
            },
            {
                path: 'orders', name: 'Orders', component: () => import('../views/Orders/Index.vue'),
                meta: {authRequired: true}
            },
   {
                path: 'orders/modal', name: 'OrdersModal', component: () => import('../views/Orders/components/Form.vue'),
                meta: {authRequired: true}
            },

        ]
    },
]


const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'hash'
})


// router.beforeEach(async (to, from, next) => {
//     const authStorage = authStore();
//     let user = authStorage.user
//     if (to.matched.some(route => route.meta.authRequired)) {
//         if (user && user.token) {
//             next()
//         } else {
//             if (localStorage.getItem('user')) {
//                 const userData = JSON.parse(localStorage.getItem('user'));
//                 const isDarkData = JSON.parse(localStorage.getItem('isDark'));

//                 authStorage.user = userData;
//                 authStorage.isDark = isDarkData;

//                 if (userData.is_admin == '1') {
//                     let array = [
//                         {
//                             action: ['read', 'update', 'delete'],
//                             subject: 'admins',
//                         },
//                         {
//                             action: ['read', 'update', 'delete'],
//                             subject: 'users',
//                         },
//                     ]
//                     if (userData.department_type == 'drobilka') {
//                         array.push(
//                             {
//                                 action: ['read', 'update', 'delete'],
//                                 subject: 'crusher',
//                             },
//                         )
//                     }
//                     ability.update(array)
//                 } else {
//                     let array = [
//                         {
//                             action: ['read', 'update', 'delete'],
//                             subject: 'users',
//                         },
//                     ]
//                     if (userData.department_type == 'drobilka') {
//                         array.push(
//                             {
//                                 action: ['read', 'update', 'delete'],
//                                 subject: 'crusher',
//                             },
//                         )
//                     }
//                     ability.update(array)
//                 }

//                 next()
//             } else {
//                 next('/')
//             }

//         }
//     } else {
//         next()
//     }
//     next()
// })


const DEFAULT_TITLE = 'LFR.uz'
router.afterEach((to, from) => {
    document.title = to.meta.title || DEFAULT_TITLE;
})


export default router