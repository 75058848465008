<template>
<section class="wrapperAll pb-24">
    <SidebarMobile @toggleSidebar="toggleSidebar" :isSidebarActive="isSidebarActive" />
    <div class="flex flex-col">
        <Header @changeTheme="changeTheme" :isDark="isDark" @toggleSidebar="toggleSidebar" />
        <FooterPages :isDark="isDark"/>
        <router-view />
    </div>
</section>
</template>

<script>
import { ref, onMounted } from 'vue'
import Header from '../layouts/components/Header.vue'
import SidebarMobile from '../layouts/components/SidebarMobile.vue'
import FooterPages from './components/FooterPages.vue'

export default {
    components: {
        Header,
        SidebarMobile,
        FooterPages
    },
    setup() {
        const isDark = ref(false)
        const isSidebarActive = ref(false)
        let isDarkValue = JSON.parse(localStorage.getItem('isDark'))

        function changeTheme(event) {
            isDark.value = event
            localStorage.setItem('isDark', JSON.stringify(event))
            let bodyWrapper = document.querySelector("body")
            
            let htmlElement = document.querySelector("html")
            if(event){
                htmlElement.classList.add("dark")
                bodyWrapper.style.backgroundColor = '#0b1929'
            }
            if(!event){
                htmlElement.classList.remove("dark")
                bodyWrapper.style.backgroundColor = 'transparent'
            }
            location.reload()
        }

        onMounted(() => {
            let htmlElement = document.querySelector("html")
            let bodyWrapper = document.querySelector("body")
            isDark.value = isDarkValue
            if (isDarkValue) {
                bodyWrapper.style.backgroundColor = '#0b1929'
            } else {
                bodyWrapper.style.backgroundColor = 'transparent'
            }
            if(isDarkValue){
                htmlElement.classList.add("dark")
            }
            if(!isDarkValue){
                htmlElement.classList.remove("dark")
            }
        })

        const toggleSidebar = () => {
            isSidebarActive.value = !isSidebarActive.value
        }
        return {
            toggleSidebar,
            isSidebarActive,

            isDark,
            changeTheme
        }
}
}
</script>

<style scoped>
</style>
