<template>
<div class="bg-[#5FC7D1] fixed w-full h-[100vh] min-h-[680px] z-[9999] duration-300 bottom-0 top-0 dark:bg-[#1f3854]" :class="isSidebarActive ? `left-[0px]` : `-left-[100vw]`">
    <div class="relative wrapper">
        <span class="absolute right-4 top-4">
            <CancelIcon
                @click="toggleSidebar"
                stroke="stroke-white rotate-45 cursor-pointer"
                width="32"
                height="32"
            />
        </span>
    </div>
    <div class="h-full py-24 wrapper">
        <slot name="filterBody"></slot>
        <div class="grid grid-cols-1 gap-5 w-full">
            <div class="relative">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="absolute left-4 top-1/2 -translate-y-1/2">
                    <path d="M18.14 21.62C17.26 21.88 16.22 22 15 22H8.99998C7.77998 22 6.73999 21.88 5.85999 21.62C6.07999 19.02 8.74998 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 2H9C4 2 2 4 2 9V15C2 18.78 3.14 20.85 5.86 21.62C6.08 19.02 8.75 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62C20.86 20.85 22 18.78 22 15V9C22 4 20 2 15 2ZM12 14.17C10.02 14.17 8.42 12.56 8.42 10.58C8.42 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58C15.58 12.56 13.98 14.17 12 14.17Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.58 10.58C15.58 12.56 13.98 14.17 12 14.17C10.02 14.17 8.42004 12.56 8.42004 10.58C8.42004 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <input
                    v-model="form.name"
                    type="text"
                    name="text"
                    autocomplete="off"
                    required
                    :placeholder="$t('search_by_name')"
                    class="w-full py-[14px] px-[24px] pl-12 focus:outline-none text-white border-[1.5px] border-[#fff] rounded-[12px] text-lg bg-white"
                />
            </div>
            <div class="relative">
                <Datepicker 
                    class="datepicker_input"
                    inputClassName="datepicker" 
                    menuClassName="dp-custom-menu" calendarClassName="dp-custom-calendar" 
                    v-model="form.date" :placeholder="$t('Поиск по дате')" 
                    :locale="locale == 'ru' ? 'ru' : 'uz'"
                    :cancelText="locale == 'ru' ? 'Отмена' : 'Bekor qilish'"
                    :selectText="locale == 'ru' ? 'ОК' : 'Tanlash'">
                </Datepicker>
            </div>
            <div class="relative">
                <svg style="fill: #fff" viewBox="0 0 24 24" width="24" height="24" class="absolute left-4 top-1/2 -translate-y-1/2">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M23 12l-7.071 7.071-1.414-1.414L20.172 12l-5.657-5.657 1.414-1.414L23 12zM3.828 12l5.657 5.657-1.414 1.414L1 12l7.071-7.071 1.414 1.414L3.828 12z" />
                </svg>
                <input
                    v-model="form.code"
                    type="text"
                    name="text"
                    autocomplete="off"
                    required
                    :placeholder="$t('search_by_code')"
                    class="w-full py-[14px] px-[24px] pl-12 focus:outline-none text-white border-[1.5px] border-[#fff] rounded-[12px] text-lg"
                />
            </div>
            <div class="relative">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="absolute left-4 top-1/2 -translate-y-1/2">
                    <path d="M8 11.4C8 12.17 8.6 12.8 9.33 12.8H10.83C11.47 12.8 11.99 12.25 11.99 11.58C11.99 10.85 11.67 10.59 11.2 10.42L8.8 9.57995C8.32 9.40995 8 9.14995 8 8.41995C8 7.74995 8.52 7.19995 9.16 7.19995H10.66C11.4 7.20995 12 7.82995 12 8.59995" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 12.8501V13.5901" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 6.40991V7.18991" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.99 17.98C14.4028 17.98 17.98 14.4028 17.98 9.99C17.98 5.57724 14.4028 2 9.99 2C5.57724 2 2 5.57724 2 9.99C2 14.4028 5.57724 17.98 9.99 17.98Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.98 19.88C13.88 21.15 15.35 21.98 17.03 21.98C19.76 21.98 21.98 19.76 21.98 17.03C21.98 15.37 21.16 13.9 19.91 13" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
    
                <input
                    v-model="form.price"
                    type="text"
                    name="text"
                    autocomplete="off"
                    required
                    :placeholder="$t('Поиск по сумме')"
                    class="w-full py-[14px] px-[24px] pl-12 focus:outline-none text-white border-[1.5px] border-[#fff] rounded-[12px] text-lg"
                />
            </div>
        </div>
        <footer class="w-full grid grid-cols-2 gap-5">
            <button
                @click="cancel"
                class="basicBtn w-full disabled-background flex justify-center text-[#fff] mt-8 text-center bg-white bg-opacity-[0.1]"
                >
                    {{$t('clean')}}
                </button>
                <button
                    @click="submit"
                    class="basicBtn whitespace-nowrap w-full bg-[#2597DE] flex justify-center text-white mt-8 text-center dark:bg-[#3d82ad]"
                >
                    {{$t('Поиск')}}
            </button>
        </footer>
    </div>
</div>
</template>

<script>
import { ref } from "vue";
import CancelIcon from "../../assets/iconsVue/PlusIcon.vue";
export default {
    props: ['isSidebarActive'],
    watch: {
        isSidebarActive: function(oldValue, newValue) {}
    },
    components: {
      CancelIcon
    },
    setup(props, context) {
        const form = ref({
            name: '',
            date: '',
            code: '',
            price: ''
        })
        const toggleSidebar = () => {
            context.emit('toggleSidebar')

            form.value = {
                name: '',
                date: '',
                code: '',
                price: ''
            }
        }

        const submit = () => {
            context.emit('submitFilter')
        }

        const cancel = () => {
            context.emit('cancelFilter')
        } 
        
        return {
           form,
           toggleSidebar,
           submit,
           cancel
        }
    },
};
</script>

<style scoped>
input,
textarea {
    caret-color: white;
    background-color: transparent;
}

input::placeholder {
    color: #fff !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}
</style>
