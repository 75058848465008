<template>
<svg :class="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
</svg>
</template>

<script>
    export default {
        props: ['stroke']
    }
</script>
