<template>
    <div class="v-select border-[1.5px] borderBox dark:border-[#888A8F] rounded-[12px]">
        <p ref="selectRef" class="value h-[53px] px-[24px] pl-12 focus:outline-none text-lg cursor-pointer flex items-center justify-between w-full overflow-hidden whitespace-nowrap" 
        :class="[classes, selectedElem || selectedElement ? 'dark:text-white dark-hover:text-white text-[#111]'
         : 'dark:text-white dark-hover:text-white text-[#696F7A] hover:text-[#696F7A]']" >
            {{ selectedElem ? $t(selectedElem) : selectedElement ? selectedElement : placeholder }}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" 
            :class="(optionVisible && !readonly) ? 'rotate-[180deg]' : ''" class="duration-300 absolute right-3">
                <path d="M19.9201 8.9502L13.4001 15.4702C12.6301 16.2402 11.3701 16.2402 10.6001 15.4702L4.08008 8.9502" :stroke="$isDark ? '#fff' : '#888A8F'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </p>
        <div 
            v-if="optionVisible && !readonly" 
            class="options shadow-2xl flex flex-col py-2 z-[99] max-h-[220px] overflow-y-scroll absolute left-0 top-16 rounded-[4px] w-full"
            :class="[$isDark ? 'bg-[#011e3c] shadow-2xl shadow-[#111] border border-[#132f4c]' : 'bg-white', positioning]">
            <span v-if="options.length">
                <p
                    v-for="(option, i) in options" 
                    :key="i"
                    @click="selectOption(option)" 
                    class="option py-2 px-4 cursor-pointer"
                    :class="$isDark ? 'hover:bg-[#111] hover:bg-opacity-[0.5] text-white hover:text-white' : 'hover:bg-[#efefef] main-color-black'"
                    >
                    {{ $t(option.title) }}
                </p>
            </span>
            <span v-else class="w-full h-[200px] flex items-center justify-center">
                <img class="w-[70px]" src="../../assets/images/no-data.png" alt="image">
            </span>
        </div>
        
    </div>
</template>

<script>
import { ref, onMounted, watch, watchEffect } from 'vue'
export default ({
    props: ['options', 'classes', 'placeholder', 'selectedElement', 'readonly', 'positioning'],
    setup(props, { emit }) {
        let optionVisible = ref(false)
        const selectRef = ref(null)
        let selectedElem = ref('')

        const openSelect = (e) => {
            if (e.target === selectRef.value) {
                optionVisible.value = !optionVisible.value
            } else {
                optionVisible.value = false
            }
        }

        const selectOption = (option) => {
            emit("select", option)
            selectedElem.value = option.title
        }

        onMounted(() => {
            window.addEventListener("click", openSelect);
        });

        return {
            optionVisible,
            selectRef,
            selectOption,
            selectedElem
        }
    }
})
</script>

<style scoped>
.v-select {
    position: relative;
}
</style>